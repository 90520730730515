import { createTheme, ThemeProvider } from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ReactGA from 'react-ga4';

import { CSContainer } from './containers/CSContainer';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './containers/ErrorPage';
import RootContainer from './containers/RootContainer';

import { NotificationProvider } from './hooks/NotificationProvider';
import { FormDataProvider } from './hooks/FormDataProvider';
import { SSContainer } from './containers/SSContainer';
import { ArrContainer } from './containers/ArrContainer';
import { ADContainer } from './containers/ADContainer';
import LandingPage from './landing-pages/LandingPage';
import { useEffect } from 'react';


const App = () => {

  
  useEffect(() => {
      ReactGA.initialize('G-QG49CFCGL9');
  }, []);

  const theme = createTheme({
    typography: {
      header: {
        fontSize: '1.3rem',
      },
      input: {
        fontSize: '0.8 rem',
      },
      value: {
        fontSize: '0.9rem',
        fontWeight: '500',
      },
      number: {
        color: '#222',
        fontSize: '0.8rem',
      },
    },
    palette: {
      primary: {
        light: '#a7c957',
        main: '#6a994e',
        dark: '#386641',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e0e1dd',
        main: '#fe9455',
        dark: '#fd7c39',
        contrastText: '#f9f9f9',
      },
      error: {
        main: '#bc4749',
        light: '#ef5350',
        dark: '#9b2226',
        contrastText: '#fff',
      },      
      success: {
        main: '#a7c957',
      },
      warning: {
        main: '#fca311',
      },
      payment: {
        over: '#277da1',
        full: '#90be6d',
        partial: '#ee964b',
        none: '#ae2012',      
      },
    },
  });
  

  const router = createBrowserRouter([

    {
      path: "/",
      element:         
          <LandingPage />,        
      errorElement: <ErrorPage />,  
    },
    {
      path: "/csg",
      element:         
          <FormDataProvider>
            <RootContainer topPageTitle="Child Support Guidelines" />
          </FormDataProvider>,        
      children: [
        {
          index: true,
          element: <CSContainer />,
        },        
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/spousal",
      element:         
          <FormDataProvider>
            <RootContainer topPageTitle="Spousal Support Calculations" />
          </FormDataProvider>,        
      children: [
        {
          index: true,
          element: <SSContainer />,
        }, 
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/arrearage",
      element: 
          <FormDataProvider>            
              <RootContainer topPageTitle="Arrearage Calculations" />            
          </FormDataProvider>,
      children: [
        {
          index: true,
          element: <ArrContainer />,
        },     
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/assetdebt",
      element: 
          <FormDataProvider>
            <RootContainer topPageTitle="Asset Debt Charts" />
          </FormDataProvider>,
      children: [    
        {
          index: true,
          element: <ADContainer />,
        },     
      ],
      errorElement: <ErrorPage />,
    },         
    {
      path: "/index.html",
      element:         
        <LandingPage />,     
      errorElement: <ErrorPage />,
    }, 
  ]);  

  return (     
    <ThemeProvider theme={theme}>
      <NotificationProvider>
          <RouterProvider router={router} />                   
      </NotificationProvider>
    </ThemeProvider>
    );   
};

export default App;


